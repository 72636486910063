<template>
  <div class="content collapsible" :class="{ opened: group.opened }">
    <div class="header">
      <p class="title">{{ name }} ({{ Object.keys(group.forms).length }})</p>
      <ChevronDown @click="toogleGroup(group)" />
    </div>
    <ul>
      <li
        v-for="(key, index) in Object.keys(group.forms)"
        :key="index"
        :class="{
          plus: !group.forms[key].showing,
          active: active === group.forms[key].id
        }"
        @click="changeActive(key)"
      >
        {{ group.forms[key].name }}
        <Check
          v-if="group.forms[key].showing"
          :class="{ confirmed: formCompleted(group.forms[key]) }"
        />
        <Plus
          v-if="!group.forms[key].showing"
          @click="ev => canEdit && addForm(ev, group.forms[key])"
        />
      </li>
      <li v-if="name === 'Exames complementares'" @click="addCustom">
        <span class="button"> Adicionar exame complementar </span>
      </li>
    </ul>
  </div>
</template>

<script>
import { formCompleted } from '@/utils/formGroups'
import ChevronDown from '@/assets/icons/chevron-down.svg'
import Check from '@/assets/icons/check.svg'
import Plus from '@/assets/icons/plus.svg'

export default {
  name: 'SideAppointment',
  props: {
    group: Object,
    name: String,
    goTo: Function,
    addCustom: Function,
    canEdit: Boolean
  },
  components: {
    ChevronDown,
    Check,
    Plus
  },
  data() {
    return {
      active: null
    }
  },
  methods: {
    formCompleted,
    addForm(ev, form) {
      form.showing = true
      form.opened = true
      this.$emit('saveForm', this.group.forms, true)
    },
    toogleGroup(group) {
      group.opened = !group.opened
    },
    changeActive(key) {
      
      if (this.canEdit) {
        this.changeActiveAndGoTo(this.group.forms[key])
      } else {
        this.goTo(this.group.forms[key])
      }
    },

    changeActiveAndGoTo(form) {
      this.active = form.id
      const getSummary = form.active;
      form.active = true
      this.goTo(form, getSummary);
    },
    completed(form) {
      function isProsCompleted(key, props) {
        // ignoring props that has default value
        if (['emUso', 'examLaterality', 'surgeryLaterality'].includes(key)) {
          return false
        }
        return (
          (props && !Array.isArray(props)) ||
          (Array.isArray(props) && props.length)
        )
      }
      return (
        (form.value && form.value.length) ||
        (form.fields &&
          Object.keys(form.fields).some(key =>
            isProsCompleted(key, form.fields[key])
          ))
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  padding: 24px;
  border-bottom: 1px solid var(--neutral-200);

  &.collapsible {
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .title {
        font-weight: 600;
        font-size: 16px;
        color: var(--dark-blue);
      }

      svg {
        width: 24px;
        height: 24px;
        stroke: var(--neutral-600);
        transform: rotate(180deg);
        transition: all 0.5s;
        cursor: pointer;
      }
    }

    ul {
      max-height: 0;
      overflow: hidden;
      transition: all 0.5s;

      .confirmed {
        stroke: var(--states-success);
      }

      .plus {
        color: var(--blue-500);
        svg {
          cursor: pointer;
          fill: var(--blue-500);
          stroke: transparent;
        }
      }
    }

    &.opened {
      .header {
        margin-bottom: 24px;
      }

      ul {
        max-height: 1000px;
        overflow: initial;
      }

      .header {
        svg {
          transform: rotate(0deg);
        }
      }
    }
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      font-weight: 600;
      font-size: 16px;
      color: var(--type-active);
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 8px 0;
      position: relative;
      cursor: pointer;

      span.button {
        padding: 8px 16px;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        border: 2px solid var(--type-active);
        border-radius: 8px;
        margin-top: 16px;
      }

      svg {
        width: 24px;
        height: 24px;
        stroke: var(--neutral-500);
      }

      &.active {
        font-weight: 700;

        &:before {
          width: 4px;
          height: 100%;
          background-color: var(--orange);
          content: ' ';
          position: absolute;
          top: 0;
          left: -24px;
        }
      }
    }
  }
}
</style>
